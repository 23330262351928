@import '~antd/dist/antd.css';
@import 'antd-button-color/dist/css/style.css';

/* html,body,#root,.ant-layout{
	width: 100%;
	height: 100%;
} */

.site-form-item-icon {
    color: rgba(0, 0, 0, 0.25);
}