.order-btn-warning {
    transition-duration: 0.4s;
    background-color: #faad14;
    color: antiquewhite;
    border-color: #faad14;
}

.order-btn-warning:hover {
    background-color: #faad14;
    color: antiquewhite;
    border-color: #faad14;
}

.order-btn-success {
    transition-duration: 0.4s;
    background-color: #52c41a;
    color: antiquewhite;
    border-color: #52c41a;
}

.order-btn-success:hover {
    background-color: #52c41a;
    color: antiquewhite;
    border-color: #52c41a;
}