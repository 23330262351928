.sku-table-container .sku-table-header {
    padding: 6px;
    overflow: hidden;
    background-color: #e8e8e8;
  }
  .sku-table-container .sku-table-select {
    width: 100%;
  }
  .sku-table-container .sku-body {
    margin-bottom: 10px;
    padding: 20px 6px;
    background-color: #f0f2f5;
  }
  .sku-table-container .sku-sub-remove-icon {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .sku-table-container .sku-sub-remove-icon:hover {
    opacity: 1 !important;
  }
  .sku-table-container .sku-sub-remove-icon svg {
    width: 16px;
    height: 16px;
  }
  .sku-table-container .sku-sub-spec {
    display: block;
    height: 32px;
    margin-right: 10px;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    white-space: nowrap;
    text-align: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  }
  .sku-table-container .sku-sub-spec:hover .sku-sub-remove-icon {
    opacity: 0.6;
  }
  /** 表格 **/
  .sku-table {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .sku-table .ant-form-item {
    margin-bottom: 0;
  }
  .sku-table .ant-form-explain {
    position: absolute;
    top: 37px;
  }
  .addGoodsFooter {
    text-align: center;
  }
  .addGoodsFooter .ant-btn {
    margin: 0 20px;
  }
  .main-img-upload {
    position: absolute;
    padding-top: 10px;
    left: 50%;
    transform: translate(-50%);
    max-height: 140px;
    overflow: hidden;
  }
  .sku-td-first {
    position: relative;
    display: flex;
    align-items: center;
  }
  .sku-td-first > img {
    width: 80px;
    margin-right: 8px;
  }
  