.menu :global(.anticon) {
  margin-right: 8px;
}
.menu :global(.ant-dropdown-menu-item) {
  min-width: 160px;
}
.right {
  display: flex;
  float: right;
  height: 60px;
  margin-left: auto;
  overflow: hidden;
}
.right .action {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.right .action > span {
  vertical-align: middle;
}
.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right .action:global(.opened) {
  background: rgba(0, 0, 0, 0.025);
}
.right .search {
  padding: 0 12px;
}
.right .search:hover {
  background: transparent;
}
.right .account .avatar {
  margin-right: 8px;
  color: #1890ff;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.062);
}
.dark .action:hover {
  background: #252a3d;
}
.dark .action:global(.opened) {
  background: #252a3d;
}
.unread {
  background-color: #f4f4f4;
  cursor: pointer;
}
.read {
  background-color: white;
  cursor: pointer;
}
.loadbackground, .ant-list-header {
  background-color: white;
}

.viewall {
  border: none;
  padding: 0 150px;
}