.steps-content {
  min-height: 200px;
  margin-top: 16px;
  border-radius: 2px;
}
  
.steps-action {
  margin-top: 24px;
  display: flex; 
  justify-content: flex-end
}