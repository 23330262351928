.btn-category:focus {
  border-color: #1890ff;
  color: #1890ff;
}

.btn-category > p {
  word-wrap: break-word;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.hidden {
  display: none;
}

.site-input-group-wrapper .site-input-split {
  background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
  border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}