.login-form {
    width: 480px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}