@import url('//fonts.googleapis.com/css?family=Poppins:300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,900,900italic');
@import url('//fonts.googleapis.com/css?family=Libre+Baskerville:300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,900,900italic');

@font-face {
  font-family: "Century Gothic";
  src: url("https://d249exs2pmafau.cloudfront.net/fonts/");
  src: url("https://d249exs2pmafau.cloudfront.net/fonts/?#iefix") format("embedded-opentype"),
    url("https://d249exs2pmafau.cloudfront.net/fonts/") format("woff"),
    url("https://d249exs2pmafau.cloudfront.net/fonts/default/GOTHIC_1.TTF") format("truetype"),
    url("https://d249exs2pmafau.cloudfront.net/fonts/#Century Gothic") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    */
  font-family: "Poppins", arial, tahoma;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*Add a New Product*/
.start_new_product_page .ant-page-header-heading-title {
  color: #254B97;
  font-size: 21px;
}
/*----------------------------General----------------------------*/
.second_step_form .ant-form-item-label > label {
  color: #626262;
  font-weight: bold;
}

.second_step_form .ant-card-head-title {
  font-size: 21px;
  color: #254B97;
  font-weight: bold;
}

.second_step_form .ant-card-head {
  border-bottom: 0px;
}

.second_step_form .ant-card {
  border-radius: 5px;
  margin-block-end: 16px;
}
.second_step_form .ant-input-number-group-addon {
  background: white;
}
/*----------------------------Name and SKU----------------------------*/
.second_step_form .product_name_card .ant-row .ant-col.is_enable_btn {
  display: flex;
  flex-direction: row-reverse;
}
.second_step_form .product_name_card .ant-card-body  {
  background: #1080D2;
  border-radius: 5px;
}

.second_step_form .product_name_card .ant-card-body .ant-form-item {
  margin: 5px 0 10px 0 !important;
}

.second_step_form .product_name_card .ant-card-body .ant-form-item-label > label {
  color: #fff;
  font-weight: bold;
}
/*----------------------------Product Information----------------------------*/
.product_info_card .quill {
  height: unset !important;
}
.product_info_card .image_border .ant-card .ant-card-body {
  padding: 0px;
}

.product_info_card .ant-upload.ant-upload-select-picture-card > .ant-upload {
  background: #fff;
  color: #1080D2;
}

.product_info_card .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #1080D2;
  width: 130px;
  height: 130px;
  margin-bottom: unset;
  margin-right: unset;
}
/*----------------------------Specification----------------------------*/
/*.second_step_form .warranty_weight .ant-form-item-control-input-content {
  margin-right: 10px;
  display: grid;
  grid-template-columns: auto auto;
}*/

.second_step_form .warranty_weight .ant-space.ant-space-horizontal.ant-space-align-center {
  display: grid;
  grid-template-columns: auto auto;
}
/*----------------------------First step----------------------------*/
.add_new_product_listing .ant-card-head-title {
  color: #254B97;
  font-weight: bold;
  font-size: 18px;
}

.add_new_product_listing .ant-card-extra {
  color: #7E7D7D;
}

.add_new_product_listing .ant-space-item button {
  width: 130px;
  height: 90px !important;
}

.add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
  flex-wrap: unset;
  gap: unset;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
}

.add_new_product_listing .ant-card-head {
  border-bottom: 0px;
}

.add_new_product_listing .ant-space-item .btn-category > p {
  color: #969696;
  font-size: 10px;
  padding-top: 10px;
}

.add_new_product_listing .ant-btn:focus, .add_new_product_listing .ant-btn:hover {
  border: 2px solid #40a9ff;
}

.add_new_product_listing .ant-btn:hover {
  border: 1px solid #40a9ff;
}

.add_new_product_listing .btn-category > p {
  white-space: normal;
}

.add_new_product_listing .ant-card-head-title {
  flex: unset;
  font-size: 23px;
  padding-bottom: 0px!important;
}

.add_new_product_listing .ant-card-extra {
  margin-left: 20px;
  padding-bottom: 0px!important;
}

.add_new_product_listing .ant-form-item-label > label::after {
  display: none;
}

.add_new_product_listing .ant-form-item-label > label {
  color: #254B97;
  font-size: 18px;
  font-weight: bold;
  padding-right: 10px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-btn.ant-btn-primary.first-next-btn {
  background: #254B97;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 15px;
  height: unset;
}

.sub_cat_flow {
  padding-top: 20px;
  border-top: 1px solid #F0F0F0;
}

/*Product Listing Page*/
.product_listing_page .ant-card-body {
  background: #F0F2F5;
  padding: 0 !important;
}

.product_listing_page .ant-tabs-nav {
  background: #fff;
  margin-bottom: 0px;
  /*border-radius: 5px 5px 0 0;*/
}

.product_listing_page .ant-tabs-nav-wrap {
  padding: 10px 36px 0 36px;
}

.product_listing_page .ant-tabs-tab .ant-tabs-tab-btn {
  color: #838383;
  font-weight: bold;
}

.product_listing_page .ant-tabs-tab-active.ant-tabs-tab .ant-tabs-tab-btn {
  color: #254B97;
}

.product_listing_page .ant-tabs-ink-bar {
  background: #254B97;
}

.product_listing_list .ant-card-body {
  border-radius: 5px;
  background: #fff;
}

.product_listing_list .product_list_table div.ant-table-header  {
  background-color: #F8F8F8;
}

.product_listing_list .product_list_table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before
{
  content:none;
}

.product_listing_list .ant-form-item-label > label::after {
  display: none;
}

.product_listing_list .ant-form-item-label > label {
  color: #585757;
  font-size: 16px;
  font-weight: bold;
  padding-right: 15px;
}

.product_listing_list .ant-table-header {
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  margin-bottom: 5px;
}

.product_listing_list .ant-table-cell {
  padding: 15px 15px !important;
}

.product_listing_list .ant-table-thead > tr > th {
  border-bottom: 0px !important;
  color: #818181;
  background-color: #F8F8F8;
}

.product_listing_list .ant-pro-table-search.ant-pro-table-search-query-filter {
  background: #F8F8F8;
  border-radius: 0 0 5px 5px;
}

.product_listing_list .add_product_btn {
  background: #254B97;
  font-weight: 550;
  border-radius: 5px;
  margin-right: 24px;
}

.product_listing_list .ant-table table tr td {
  border-top: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
}

.product_listing_list .ant-table table tr td:first-child {
  border-left: 1px solid #F5F5F5;
  border-radius: 5px 0 0 5px;
}

.product_listing_list .ant-table table tr td:last-child {
  border-right: 1px solid #F5F5F5;
  border-radius: 0 5px 5px 0;
}

.product_listing_list .ant-table table {
  
  border-collapse: separate;
  border-spacing: 0 10px;
}

/*General*/
.site-layout .site-layout-background {
  background: none;
  margin: 0;
  padding-top: 0;
}

.order_info_page_title .ant-descriptions-title {
  color: #254B97;
  font-size: 20px;
  margin-bottom: 20px;
}

.order_info_page_title .ant-card-head-title {
  color: #254B97;
  font-size: 20px;
  font-weight: bold;
}

.order_info_page_title .ant-card-head {
  border-bottom: 0px;
}

/*Table format in Order Information page*/
.order_info_page_title .ant-descriptions-view {
  border: 0px;
}

.order_info_page_title .ant-descriptions-view th,
tr,
td {
  border: 0px;
}

.order_info_page_title .ant-descriptions-view tr {
  border: 0px;
}

.order_info_page_title .ant-descriptions-view th {
  background-color: white;
}

.order_info_page_title .ant-table-content th {
  background-color: #F8F8F8;
}

/*Notes for this order*/
.total_summary_page .ant-switch {
  position: absolute;
  top: 0;
  right: 0;
}

.total_summary_page .ant-descriptions-header {
  margin-bottom: 0px;
}

.order_notes_component .order_info_page_title .ant-descriptions-title {
  margin-bottom: 0px;
}

.order_notes_component .ant-card.ant-card-bordered {
  border-radius: 10px;
}

/*Order Totals*/
.order_info_page_title .order_totals_table .ant-descriptions-view th {
  background-color: transparent;
}

.order_info_page_title .order_totals_table .ant-table-content th {
  background-color: transparent;
}

.order_totals_component .ant-descriptions-item-content {
  display: flex;
  justify-content: flex-end;
}

.order_totals_component .ant-card.ant-card-bordered {
  border-radius: 10px;
}

.order_totals_component .order_totals_table .ant-descriptions-view .ant-descriptions-item-label,
.order_totals_component .order_totals_table .ant-descriptions-view .ant-descriptions-item-content {
  font-weight: bold;
}

.order_totals_component .order_totals_table2 .ant-descriptions-view .ant-descriptions-item-label,
.order_totals_component .order_totals_table2 .ant-descriptions-view .ant-descriptions-item-content {
  font-weight: bold;
}

.order_totals_component .order_totals_table2 .ant-descriptions-view {
  border: 2px solid #EFEFEF;
  border-radius: 5px;
}

.order_totals_component .order_totals_table .ant-descriptions-view tr {
  border-radius: 10px;
  margin-bottom: 10px;
}

/*Items Ordered*/
.items_ordered_component .ant-table-wrapper {
  overflow-y: auto;
}
.items_ordered_component .ant-card.ant-card-bordered {
  border-radius: 10px;
}

.purchase_item {
  padding: 5px;
}

.purchase_qty {
  background: #ECECEC;
  padding: 5px;
  border-radius: 5px;
}

/*Account Information*/
.ant-descriptions-item-content.addr_sub_title .ant-descriptions-title {
  color: #3E3E3E;
  font-size: 18px;
}

.acc_info_component {
  border: 0px;
}

.acc_info_component .ant-card-body,
.acc_info_component .ant-card-head {
  background: #F0F2F5;
}

.acc_info_component .ant-card.ant-card-bordered {
  border-radius: 10px;
  border: 0px;
}

.acc_info_component .order_info_details {
  display: flex;
  align-items: center;
}

.acc_info_component .order_info_details .order_id {
  color: #1080D2;
  font-weight: bold;
}

.acc_info_component .order_info_details .order_time {
  color: #8A8989;
  font-size: 12px;
}

.acc_info_component .order_info_details .order_status {
  font-weight: bold;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 20px;
}

.acc_info_component .order_info_details .order_status.pending_payment {
  color: #ccc;
  border: 3px solid #ccc;
}

.acc_info_component .order_info_details .order_status.processing {
  color: #FF9700;
  border: 3px solid #FF9A00;
}

.acc_info_component .order_info_details .order_status.complete {
  color: #88C059;
  border: 3px solid #88C059;
}

.acc_info_component .order_info_details .order_status.canceled {
  color: #666;
  border: 3px solid #666;
}

.acc_info_component .order_info_details .order_status.closed {
  color: #FF0000;
  border: 3px solid #FF0000;
}

.acc_info_component .order_info_details .order_buttons {
  margin-left: auto;
}

.acc_info_component .ant-card-body {
  padding: 0px;
}

/*Order ID details*/
.acc_info_component .ant-card-head-title {
  background: white;
  border-radius: 10px;
  padding: 16px;
}

.acc_info_component .ant-card-head {
  padding: 0px 0px;
}

/*Acc info details*/
.order_info_page_title,
.column_style,
.order_info_page_title,
.column_style2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.order_info_page_title.order_acc_table {
  background: white;
  padding: 16px 16px !important;
  border-radius: 10px;
  margin: 10px 5px 10px 10px;
  min-height: 336px;
}

.order_info_page_title.order_addr_table {
  background: white;
  padding: 16px 16px !important;
  border-radius: 10px;
  margin: 10px 10px 10px 5px;
  min-height: 336px;
}

.acc_info_component .order_info_page_title.order_addr_table td {
  border: 0px;
}

/*Address info details*/
.order_addr_table .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 0px 0px;
}

.order_addr_table .ant-descriptions-item {
  padding-bottom: 0px!important;
}

.order_addr_table .ant-descriptions-header {
  margin-bottom: 0px;
}

/*Order Page Left Panel*/
.order_view_left_panel .ant-tabs-nav {
  display: none;
}

.ant-layout-header {
  height: 50px;
  line-height: 50px;
}

.trigger {
  line-height: 50px;
}

.right {
  height: 50px;
  padding-right: 30px;
}

.right .action {
  height: 50px;
}

.logo {
  background-color: #fff;
  margin: 0;
  padding: 0 10px;
  text-align: center;
}

.logo img {
  height: 50px !important;
  width: auto !important;
}

.ant-layout-sider-collapsed .logo img {
  width: 100%;
  height: auto;
  padding: 9px 0;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  margin: 0;
}

.ant-layout-sider,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #1080D2;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #254B96;
}

.ant-avatar-string {
  background-color: #254B96;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}


/* dashboard CSS */
.dashboard_top {
  border: none;
}

.dashboard_top .ant-pro-card-body {
  padding: 6px;
}

.dashboard_top .credit_amount_box:hover .ant-statistic-content-suffix,
.dashboard_top .lifetime_sales_box:hover .ant-statistic-content-suffix,
.dashboard_top .average_orders_box:hover .ant-statistic-content-suffix,
.dashboard_top .total_products_box:hover .ant-statistic-content-suffix {
  transform: scale(1.2);
}

.dashboard_top .ant-statistic {
  padding: 10px;
  color: #fff;
  height: 100px;
}

.dashboard_top .ant-statistic-title,
.dashboard_top .ant-statistic-content {
  color: #fff;
}

.dashboard_top .ant-statistic-title {
  position: absolute;
  bottom: 22px;
}

.dashboard_top .ant-statistic-content {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
}

.dashboard_top .ant-statistic-content-suffix {
  position: absolute;
  font-size: 68px;
  color: rgba(0, 0, 0, 0.15);
  bottom: 15px;
  right: 15px;
}

.credit_amount_box {
  background-color: #DD4B39;
}

.lifetime_sales_box {
  background-color: #F39C12;
}

.average_orders_box {
  background-color: #00A65A;
}

.total_products_box {
  background-color: #605CA8;
}

.table-img {
  height: 120px;
  text-align: center;
}

.product_img {
  max-height: 120px;
}

.order_rightAction {
  text-align: right;
  padding: 12px 5px 12px 0 !important;
  font-weight: bold;
}

.shipping_name {
  font-weight: bold;
}

.rightAction {
  text-align: right;
}

.prod_qty span {
  background-color: #ECECEC;
  padding: 4px 6px;
  border-radius: 6px;
}

.ant-table-tbody>tr>td {
  border: none;
}

.subItem>div>div.ant-card-body {
  padding: 0;
}

.subItem {
  margin-bottom: 10px !important;
  border: 1px solid #f0f0f0;
  border-top: none;
  border-radius: 0 0 5px 5px;
}

tr.ant-table-expanded-row>td,
tr.ant-table-expanded-row:hover>td {
  background: none;
}

th.order_total {
  text-align: center !important;
}

td.order_total {
  text-align: center !important;
  font-weight: bold;
}

td.prod_total {
  text-align: center !important;
}

th.order_status,
td.prod_status,
th.order_channel,
td.prod_channel,
th.order_countdown,
td.prod_countdown {
  text-align: center !important;
}

.btnDetail {
  color: #52A5EC;
  border: 1px solid #52A5EC;
  border-radius: 4px;
  font-weight: bold;
}

.btnDownload {
  color: #fff;
  background-color: #52A5EC;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 10px;
}

.order_status_txt {
  border: 1px solid;
  border-radius: 20px;
  text-align: center;
  display: inline;
  padding: 2px 10px;
  text-transform: capitalize;
}

.order_status_txt.pending_payment {
  color: #ccc;
  border-color: #ccc;
}

.order_status_txt.processing, .order_status_txt.to_ship, .order_status_txt.shipping  {
  color: #FF9700;
  border-color: #FF9700;
}

.order_status_txt.complete {
  color: #88C059;
  border-color: #88C059;
}

.order_status_txt.canceled {
  color: #666;
  border-color: #666;
}

.order_status_txt.closed {
  color: #FF0000;
  border-color: #FF0000;
}

.order_table tr {
  cursor: pointer;
}

.order_table .ant-table-measure-row > td {
  padding-top: 10px !important;
}

.order_table .ant-table-row-level-0>td {
  border-top: 1px solid #f0f0f0;
  border-bottom: none !important;
}

.order_table .subItem .ant-table-row-level-0>td {
  border: none !important;
}

.order_table .order_table_row.ant-table-row-level-0>td {
  background-color: #F8F8F8;
}

/* .order_table .ant-table-expanded-row-level-1 > td {
  border-bottom: 1px solid #f0f0f0;
  border-top: none !important;
  border-radius: 10px;
} */

.order_table .ant-table-row-level-0>td:first-child {
  border-left: 1px solid #f0f0f0;
  border-radius: 5px 0 0 0;
}

.order_table .ant-table-row-level-0>td:last-child {
  border-right: 1px solid #f0f0f0;
  border-radius: 0 5px 0 0;
}

.btn_order_action {
  height: auto !important;
  margin: 5px 0;
}

.ant-table-body {
  padding-right: 2px;
}
/* lg */
@media (max-width: 1199px) {
  .order_date {
    font-size: 11px;
    color: #aaa;
  }

  .order_price,
  .product_price {
    font-weight: bold;
    font-size: 12px;
  }

  .order_status_txt  {
    font-size: 12px;
  }

  .product_sku,
  .product_stock {
    font-size: 12px;
  }

}

@media (max-width: 1435px) {
  .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
    grid-template-columns: auto auto auto auto auto auto auto;
  }
}

@media (max-width: 1285px) {
  .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
    grid-template-columns: auto auto auto auto auto auto;
  }
}

@media (max-width: 1200px) {
  .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
    grid-template-columns: auto auto auto auto auto;
  }
}

@media (max-width: 999px) {
  .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
    grid-template-columns: auto auto auto auto;
  }
}

@media (max-width: 992px) {
  .second_step_form .product_name_card .ant-card-body .ant-form-item-label > label { 
  width: 120px;
}
}

@media (max-width: 947px) {
  .second_step_form .warranty_weight .ant-space.ant-space-horizontal.ant-space-align-center {
    display: block;
  }
}

/* xs and sm */
@media (max-width: 767px) {
  .second_step_form .shipping_details .ant-space.ant-space-horizontal.ant-space-align-center {
    display: block;
  }

  .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
    flex-wrap: unset;
    gap: unset;
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .frame-slider {
    flex: unset!important;
    max-width: unset!important;
    min-width: unset!important;
    width: unset!important;
  }

  .frame-slider .dashboard_menu_icon .ant-menu-title-content {
    display: none;
  }

  .frame-slider .logo {
    text-align: unset!important;
    padding: 0;
  }

  .frame-slider .dashboard_logo_img {
    height: 20px !important;
    width: auto !important;
  }

  .frame-slider .ant-menu-item.dashboard_menu_icon {
    padding-left: 0px !important;
    justify-content: center;
  }

  .total_summary_page .ant-switch {
    position: unset;
    top: unset;
    right: unset;
  }

  .total_summary_page .ant-switch .ant-switch-handle {
    position: sticky;
  }

  .ant-menu-inline .ant-menu-item {
    padding: 0px;
  }

  .mobile_order_view_left_panel .ant-tabs-content.ant-tabs-content-left .ant-tabs-tabpane {
    padding-left: 0px !important;
  }

  .ant-page-header {
    padding: 0px 0px !important;
  }

  .acc_info_component .order_info_details .order_id {
    color: #1080D2;
    font-weight: bold;
    font-size: 12px;
  }

  .acc_info_component .order_info_details .order_id {
    color: #1080D2;
    font-weight: bold;
    font-size: 12px;
  }

  .acc_info_component .order_info_details .order_status {
    color: #FF9A00;
    font-weight: bold;
    border: 3px solid #FF9A00;
    border-radius: 20px;
    font-size: 12px;
    padding: 2px 10px;
  }

  .acc_info_component .ant-row {
    display: block;
  }
    
  .ant-pro-table-search, .ant-pro-table-search .pro-form-query-filter-actions {
    margin: 0;
    padding: 0;
  }
  .ant-pro-table-search .pro-form-query-filter-actions .ant-form-item-label {
    display: none;
  }
  
  @media (max-width: 536px) {
    .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
      flex-wrap: unset;
      gap: unset;
      display: grid;
      grid-template-columns: auto auto;
    }
  }
  
  @media (max-width: 385px) {
    .add_new_product_listing .ant-space.ant-space-horizontal.ant-space-align-center {
      flex-wrap: unset;
      gap: unset;
      display: grid;
      grid-template-columns: auto;
    }
  
    .add_new_product_listing .ant-card-body {
      text-align: center;
    }
  }
}