.site-layout .site-layout-background {
    background: #fff;
}

.logo {
    margin: 16px 10px;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.frame-header {
    padding: 0;
}

.frame-content {
    margin: 24px 16px;
    padding: 24px;
    min-height: 280px;
    /* overflow: scroll; */
}